import { Offset, Popup } from "@progress/kendo-react-popup";
import * as React from "react";
import { useDispatch } from "react-redux";
import { startMoving } from "../../../store/features/folder/moveFilesAndFoldersSlice";
import { EntryType, FileViewEntry } from "../viewModel/FileViewEntry";
import { Organisation } from "../../organisation/domain/types";

interface FolderContextMenuProps {
    dataItem: FileViewEntry;
    folderId?: string;
    offset: Offset;
    close: () => void;
    canUpdateFiles?: boolean;
    canMoveFiles?: boolean;
    canDeleteFiles?: boolean;
    canUpdateFolders?: boolean;
    canMoveFolders?: boolean;
    canDeleteFolders?: boolean;
    onEditClick: () => void;
    onDeleteFolderClick?: () => void;
    onDeleteFileClick: () => void;
    onDownloadClick: () => void;
    organisation?: Organisation;
}

const DropdownItem: React.FC<React.HTMLAttributes<HTMLDivElement>> = (
    props,
) => {
    return (
        <div className="dropdown-item" role="button" {...props}>
            {props.children}
        </div>
    );
};

const MenuItems: React.FC<FolderContextMenuProps> = (props) => {
    const { dataItem } = props;

    switch (dataItem?.type) {
        case EntryType.Folder:
            return <FolderMenuItems {...props} />;
        case EntryType.File:
            return <FileMenuItems {...props} />;
        default:
            return null;
    }
};

const FolderMenuItems: React.FC<FolderContextMenuProps> = ({
    dataItem,
    close,
    canUpdateFolders,
    canMoveFolders,
    canDeleteFolders,
    onEditClick,
    onDeleteFolderClick,
}) => {
    const dispatch = useDispatch();

    return (
        <>
            {canUpdateFolders && (
                <DropdownItem
                    onClick={() => {
                        onEditClick();
                        close();
                    }}
                >
                    Rename
                </DropdownItem>
            )}
            {canMoveFolders && (
                <DropdownItem
                    onClick={() => {
                        dispatch(
                            startMoving({
                                files: [],
                                folders: [
                                    { id: dataItem.id, name: dataItem.label },
                                ],
                            }),
                        );
                        close();
                    }}
                >
                    Move
                </DropdownItem>
            )}
            {canDeleteFolders && (
                <DropdownItem
                    onClick={() => {
                        if (
                            window.confirm(
                                `Are you sure you want to delete the folder "${dataItem.label}"?`,
                            )
                        ) {
                            onDeleteFolderClick();
                        }
                        close();
                    }}
                >
                    Delete
                </DropdownItem>
            )}
        </>
    );
};

const FileMenuItems: React.FC<FolderContextMenuProps> = ({
    close,
    dataItem,
    canUpdateFiles,
    canMoveFiles,
    canDeleteFiles,
    onEditClick,
    onDeleteFileClick,
    onDownloadClick,
    organisation,
}) => {
    const dispatch = useDispatch();

    return (
        <>
            <DropdownItem
                onClick={() => {
                    onDownloadClick();
                    close();
                }}
            >
                Download
            </DropdownItem>
            {canUpdateFiles && (
                <DropdownItem
                    onClick={() => {
                        onEditClick();
                        close();
                    }}
                >
                    Rename
                </DropdownItem>
            )}
            {canMoveFiles && (
                <DropdownItem
                    onClick={() => {
                        dispatch(
                            startMoving({
                                files: [
                                    { id: dataItem.id, name: dataItem.label },
                                ],
                                folders: [],
                            }),
                        );
                        close();
                    }}
                >
                    Move
                </DropdownItem>
            )}
            {canDeleteFiles && (
                <DropdownItem
                    onClick={() => {
                        if (
                            window.confirm(
                                `Are you sure you want to delete the ${
                                    organisation?.features?.files
                                        ?.pageHeaderName ?? "item"
                                } "${dataItem.label}"?`,
                            )
                        ) {
                            onDeleteFileClick();
                        }
                        close();
                    }}
                >
                    Delete
                </DropdownItem>
            )}
        </>
    );
};

export const FileOrFolderContextMenu: React.FC<FolderContextMenuProps> = (
    props,
) => {
    const { offset, dataItem, close } = props;
    const divRef = React.useRef<HTMLDivElement>(null);
    return (
        <>
            <Popup
                offset={offset}
                show={dataItem !== null}
                onOpen={() => divRef.current?.focus()}
                popupClass="p-0"
            >
                <div
                    tabIndex={1}
                    ref={divRef}
                    onBlur={close}
                    className="dropleft"
                >
                    {/*
                    Bootstrap classes are used instead of using `import { Dropdown } from "react-bootstrap";` because the component
                    has some JS that conflicts with Kendo Grid. When it is used raw like that, than it works fine.
                    */}
                    <div className="dropdown-menu show">
                        <MenuItems {...props} />
                    </div>
                </div>
            </Popup>
        </>
    );
};
