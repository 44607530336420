import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { ProcessActivityPreview } from "../domain/types";
import ActivityNavigationMenu from "./ActivityNavigationMenu";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";
import useIsClient from "../../../common/hooks/useIsClient";
import CefaConstants from "../../cefa/constants/CefaConstants";
import { CefaExamContextBar } from "../../cefa/examinations/components/CefaExamContextBar";

interface ActivityLayoutProps {
    activity: ProcessActivityPreview;
    children: React.ReactNode;
}

const ActivityLayout: React.FC<ActivityLayoutProps> = ({
    activity,
    children,
}) => {
    const { organisationId } = useOrganisationId();
    const isCefa = useIsClient(CefaConstants.ClientName);
    return (
        <>
            {isCefa && (
                <CefaExamContextBar
                    organisationId={organisationId}
                    processId={activity?.processId}
                />
            )}
            <Row>
                <Col>
                    <Row>
                        <Col xs="auto" className="pr-0">
                            <ActivityNavigationMenu activity={activity} />
                        </Col>
                        <Col className="process-activity-column">
                            {children}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default ActivityLayout;
