import { HttpMethod } from "../../../common/http/HttpMethod";
import { AuditAction } from "../../../common/types/AuditAction";
import {
    AssetUrlPaths,
    getBaseAssetUrl,
} from "../../../modules/asset/helpers/urlBuilder";
import { assetCefaQueryKeysTranslator } from "../../../modules/cefa/customization/asset/query/assetCefaQueryKeysTranslator";
import { TemplateContent } from "../../../modules/template/domain/types";
import { wrapTagsWithQueryInvalidation } from "../../../query/configureQuery";
import { dcpApi, Tags } from "../dcpApi";

export interface AssetCreateModel {
    id: string;
    name: string;
    description: string;
    assetTemplateId: string;
    organisationId: string;
    parentId: string;
}

export interface ActivityPreview {
    processId: string;
    id: string;
    processName: string;
    activityName: string;
}

export interface AssetModel {
    id: string;
    name: string;
    description: string;
    auditActions: AuditAction[];
    contents: TemplateContent[];
    organisationId: string;
    parent: AssetModel;
    children: AssetModel[];
    tags: AssetTag[];
}

export interface AssetPreviewModel {
    id: string;
    name: string;
    description: string;
}

export interface AssetProperty {
    id: string;
    value: string;
}

export interface AssetWithPropsModel {
    id: string;
    name: string;
    description: string;
    properties: AssetProperty[];
}

export interface AssetTemplateModel {
    id: string;
    name: string;
    templateProperties: { id: string; name: string }[];
}

export interface AssetTag {
    id: string;
    text: string;
    organisationId: string;
}

export interface AssetUpdateModel {
    id: string;
    name: string;
    description: string;
    organisationId: string;
}

export interface AssetQueryParams {
    organisationId: string;
    name?: string;
    description?: string;
    sortBy?: string;
    sortByIsDesc?: boolean;
}

export interface ActivityAssetContQueryParams extends AssetQueryParams {
    activityId: string;
    continuationToken?: string;
}

export interface AssetContQueryParams extends AssetQueryParams {
    continuationToken?: string;
}

export interface AssetGetParams {
    organisationId: string;
    assetId: string;
}

const clearAssetTagCache = (asset: {
    id: string;
    organisationId: string;
    assetId: string;
    text: string;
}) => {
    return [
        {
            type: Tags.Asset,
            id: asset.assetId,
        },
        {
            type: Tags.OrganisationAssetTags,
            id: asset.organisationId,
        },
    ];
};

const { tagsToQueryKeys: tagsToAssetCefaQueryKeys } =
    assetCefaQueryKeysTranslator;

export const assetApi = dcpApi.injectEndpoints({
    endpoints: (builder) => ({
        getAssetTags: builder.query<AssetTag[], string>({
            query: (organisationId) =>
                `${getBaseAssetUrl(organisationId)}${AssetUrlPaths.Tags}`,
            providesTags: (_result, error, id) =>
                error ? [] : [{ type: Tags.OrganisationAssetTags, id }],
        }),
        removeAssetTag: builder.mutation<
            void,
            {
                id: string;
                organisationId: string;
                assetId: string;
                text: string;
            }
        >({
            query: (assetTag) => ({
                url: `${getBaseAssetUrl(assetTag.organisationId)}/${
                    assetTag.assetId
                }${AssetUrlPaths.Tags}`,
                method: HttpMethod.Delete,
                body: assetTag,
            }),
            invalidatesTags: (_result, error, asset) =>
                error ? [] : clearAssetTagCache(asset),
        }),

        updateAssetTag: builder.mutation<
            void,
            {
                id: string;
                organisationId: string;
                assetId: string;
                text: string;
            }
        >({
            query: (assetTag) => ({
                url: `${getBaseAssetUrl(assetTag.organisationId)}/${
                    assetTag.assetId
                }${AssetUrlPaths.Tags}`,
                method: "PUT",
                body: assetTag,
            }),
            invalidatesTags: (_result, error, asset) =>
                error ? [] : clearAssetTagCache(asset),
        }),

        deleteAsset: builder.mutation<void, AssetModel>({
            query: (asset) => ({
                url: `${getBaseAssetUrl(asset.organisationId)}/${asset.id}`,
                method: "DELETE",
            }),
            invalidatesTags: (_result, error, asset) =>
                error
                    ? []
                    : [
                          {
                              type: Tags.Asset,
                              id: asset.id,
                          },
                          {
                              type: Tags.Asset,
                              id: asset.parent?.id,
                          },
                          {
                              type: Tags.OrganisationAssets,
                              id: asset.organisationId,
                          },
                      ],
        }),

        postAssetContent: builder.mutation<
            boolean,
            { assetParams: AssetGetParams; formData: FormData }
        >({
            query: ({ assetParams, formData }) => ({
                url:
                    `${getBaseAssetUrl(assetParams.organisationId)}/${
                        assetParams.assetId
                    }` + AssetUrlPaths.Content,
                method: "POST",
                body: formData,
            }),
            invalidatesTags: (_result, error, query) =>
                error
                    ? []
                    : wrapTagsWithQueryInvalidation(
                          [{ type: Tags.Asset, id: query.assetParams.assetId }],
                          tagsToAssetCefaQueryKeys,
                      ),
        }),
        putAssetById: builder.mutation<
            AssetModel,
            Pick<AssetUpdateModel, "id"> & Partial<AssetUpdateModel>
        >({
            query: (asset) => ({
                url: `${getBaseAssetUrl(asset.organisationId)}/${asset.id}`,
                method: "PUT",
                body: asset,
            }),
            invalidatesTags: (updatedAsset, error, arg) =>
                error
                    ? []
                    : wrapTagsWithQueryInvalidation(
                          [
                              { type: Tags.Asset, id: arg.id },
                              {
                                  type: Tags.OrganisationAssets,
                                  id: arg.organisationId,
                              },
                          ],
                          tagsToAssetCefaQueryKeys,
                      ),
        }),

        getAssetById: builder.query<AssetModel, AssetGetParams>({
            query: (assetParams) =>
                `${getBaseAssetUrl(assetParams.organisationId)}/${
                    assetParams.assetId
                }`,
            providesTags: (_result, error, params: AssetGetParams) =>
                error ? [] : [{ type: Tags.Asset, id: params.assetId }],
        }),

        postAsset: builder.mutation<
            AssetCreateModel,
            Partial<AssetCreateModel>
        >({
            query: (asset) => ({
                url: `${getBaseAssetUrl(asset.organisationId)}`,
                method: "POST",
                body: asset,
            }),
            invalidatesTags: (_result, error, arg) =>
                error
                    ? []
                    : wrapTagsWithQueryInvalidation(
                          [
                              { type: Tags.Asset, id: arg.parentId },
                              {
                                  type: Tags.OrganisationAssets,
                                  id: arg.organisationId,
                              },
                          ],
                          tagsToAssetCefaQueryKeys,
                      ),
        }),
    }),
});

export const {
    usePostAssetMutation,
    useGetAssetByIdQuery,
    usePutAssetByIdMutation,
    usePostAssetContentMutation,
    useDeleteAssetMutation,
    useUpdateAssetTagMutation,
    useGetAssetTagsQuery,
    useRemoveAssetTagMutation,
} = assetApi;
