import * as React from "react";
import { Button } from "react-bootstrap";
import CoveringLoadingPanel from "../../../../../common/components/CoveringLoadingPanel";
import { AppRoutes } from "../../../../../router/AppRoutes";
import { useNavigation } from "../../../../../router/useNavigate";
import {
    AggregatedDefectData,
    ExaminationTrackingDefect,
} from "../../domain/examinationsReview";
import { useGetExaminationDefectsQuery } from "../../query/examinationsReviewQueries";
import DefectsGrid from "./DefectsGrid";
import RefreshDefectsModal from "./RefreshDefectsPrompt";
import { examinationTrackingObjectsGridMap } from "./defectsGridMaps";

interface Props {
    organisationId: string;
    reviewId: string;
}

const dataIsValid = (data: AggregatedDefectData): boolean =>
    Boolean(data) &&
    Boolean(data.processId) &&
    Boolean(data.trackingDefectsActivityId) &&
    Boolean(data.trackingDefects);

const ExaminationDefectsGrid: React.FC<Props> = ({
    organisationId,
    reviewId,
}) => {
    const { navigateToWithOrg } = useNavigation();
    const [refreshDefects, setRefreshDefects] = React.useState<boolean>(false);

    const { data, isLoading } = useGetExaminationDefectsQuery(
        organisationId,
        reviewId,
    );

    if (isLoading) return <CoveringLoadingPanel />;

    return (
        <>
            {refreshDefects && (
                <RefreshDefectsModal
                    organisationId={organisationId}
                    reviewId={reviewId}
                    onClose={() => setRefreshDefects(false)}
                />
            )}

            <div className="pt-2">
                <Button
                    className="mr-1"
                    onClick={() => {
                        navigateToWithOrg(AppRoutes.ProcessActivity, {
                            processId: data.processId,
                            activityId: data.trackingDefectsActivityId,
                        });
                    }}
                >
                    Edit
                </Button>

                <Button
                    variant="secondary"
                    className="mr-1"
                    onClick={() => {
                        setRefreshDefects(true);
                    }}
                >
                    Refresh
                </Button>
            </div>

            {dataIsValid(data) ? (
                <DefectsGrid<ExaminationTrackingDefect>
                    data={data?.trackingDefects}
                    isLoading={isLoading}
                    processId={data.processId}
                    columnsDefinitions={examinationTrackingObjectsGridMap}
                />
            ) : (
                <div className="p-2">No defects to display.</div>
            )}
        </>
    );
};

export default ExaminationDefectsGrid;
