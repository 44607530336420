import {
    FilePreview,
    FolderUploadData,
    FolderUploadInfo,
    SubFolder,
} from "../domain/folder";

export interface ExtendedUploadInfo extends FolderUploadInfo {
    newFiles: number[];
}

const createEmpty = (): FolderUploadData => ({
    subFolders: {},
});

const createEmptySubFolder = (): SubFolder => ({
    subFolders: {},
    files: [],
});

const addOrUpdate = (
    path: string[],
    subFolders: Record<string, SubFolder>,
    filePreview: FilePreview,
): void => {
    const [subFolderName, ...restPath] = path;

    if (!subFolders[subFolderName]) {
        updateSubFolder(subFolders[subFolderName], restPath, filePreview);
    } else {
        const newSubFolder = updateSubFolder(null, restPath, filePreview);

        subFolders[subFolderName] = newSubFolder;
    }
};

const updateSubFolder = (
    subFolder: SubFolder,
    path: string[],
    filePreview: FilePreview,
): SubFolder => {
    if (subFolder === null) {
        subFolder = createEmptySubFolder();
    }

    if (path.length === 0) {
        subFolder.files.push(filePreview);

        return subFolder;
    }

    addOrUpdate(path, subFolder.subFolders, filePreview);
    return subFolder;
};

const appendFile = (
    form: FolderUploadData,
    path: string[],
    file: File,
    fileIndex: number,
): void => {
    const name = file.name;

    addOrUpdate(path, form.subFolders, { name, index: fileIndex });
};

const pathRegex = /^(?:(.+)\/)?([^/]+)$/;

export const buildFolderUpload = (
    files: FileList,
): {
    parsedFiles: { path: string[]; file: File; index: number }[];
    uploadForm: FolderUploadData;
} => {
    const uploadForm = createEmpty();
    const parsedFiles = [];

    Array.from(files).forEach((file, index) => {
        const webkitPath = file.webkitRelativePath;
        const pathMatch = webkitPath.match(pathRegex);

        if (pathMatch.length !== 3) {
            throw new Error("Folder structure could not be recognized");
        }

        const path = pathMatch[1].split("/");

        appendFile(uploadForm, path, file, index);
        parsedFiles.push({ path, file, index });
    });

    return { uploadForm, parsedFiles };
};
