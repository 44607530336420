import { DataResult, process, State } from "@progress/kendo-data-query";
import {
    Grid,
    GridColumn,
    GridEvent,
    GridNoRecords,
    GridRowProps,
} from "@progress/kendo-react-grid";
import classNames from "classnames";
import * as React from "react";
import { useCallback, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { ColumnMenuFilter } from "../../../../common/components/table/kendo/columnFilters";
import CustomPagingFooter from "../../../../common/components/table/kendo/CustomPagingFooter";
import GridLoadingPanel from "../../../../common/components/table/kendo/GridLoadingPanel";
import useGridProps from "../../../../common/components/table/kendo/useGridProps";
import useInfiniteDataResult from "../../../../common/hooks/useInfiniteDataResult";
import { useNavigation } from "../../../../router/useNavigate";
import { Work } from "../domain/Work";
import { useGetInfiniteWork } from "../query/MyWorkQueries";
import { MyWorkGridClaimButton } from "./MyWorkGridClaimButton";

interface Props {
    organisationId: string;
}

const scrollCondition = (event: GridEvent): boolean => {
    const e = event.nativeEvent;

    return (
        e.target.scrollTop + 10 >= e.target.scrollHeight - e.target.clientHeight
    );
};
const MyWorkGrid: React.FC<Props> = ({ organisationId }) => {
    const { navigateToOrgPath: navigateToPath } = useNavigation();
    const wrapperRef = React.createRef<HTMLElement>();

    const {
        data: work,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
    } = useGetInfiniteWork(organisationId);

    const {
        flatData: flatWorkData,
        totalCount,
        dataCount,
    } = useInfiniteDataResult(work);

    const isLoadingWork = isFetching || isFetchingNextPage;

    const createDataState = useCallback(
        (dataState: State) => {
            return {
                result: process(flatWorkData.slice(0), dataState),
                dataState: dataState,
            };
        },
        [flatWorkData],
    );

    const [result, setResult] = React.useState<DataResult>(
        createDataState({}).result,
    );
    const [dataState, setDataState] = React.useState<State>(
        createDataState({}).dataState,
    );

    useEffect(() => {
        const updatedState = createDataState(dataState);
        setResult(updatedState.result);
    }, [createDataState, dataState, flatWorkData]);

    const scrollHandler = useDebouncedCallback((event: GridEvent) => {
        if (scrollCondition(event) && hasNextPage && !isLoadingWork) {
            fetchNextPage();
        }
    }, 200);

    const onDataStateChange = useCallback(
        (e) => {
            const updatedState = createDataState(e.dataState);
            setResult(updatedState.result);
            setDataState(updatedState.dataState);
        },
        [createDataState],
    );

    const rowRender = (
        row: React.ReactElement<HTMLTableRowElement>,
        rowProps: GridRowProps,
    ) => {
        const item = rowProps.dataItem as Work;

        const trProps = {
            ...row.props,
            className: classNames(
                {
                    "table-danger":
                        item.plannedDateJs?.getTime() <= Date.now() ?? false,
                },
                row.props.className,
            ),
        };
        return React.cloneElement(row, { ...trProps }, row.props.children);
    };

    const onNavigate = React.useCallback(
        (processId: string, id: string) => {
            navigateToPath(
                `/:orgShortName/process/${processId}/activity/${id}`,
            );
        },
        [navigateToPath],
    );

    const { gridProps } = useGridProps(result.data, {
        initialSort: [
            { field: "elr", dir: "asc" },
            { field: "miles", dir: "asc" },
            { field: "yards", dir: "asc" },
        ],
    });

    return (
        <>
            <Grid
                {...gridProps}
                {...dataState}
                onDataStateChange={onDataStateChange}
                dataItemKey={"id"}
                onScroll={scrollHandler}
                fixedScroll={true}
                reorderable={true}
                resizable={true}
                sortable={{
                    allowUnsort: true,
                    mode: "multiple",
                }}
                onRowDoubleClick={(e) =>
                    onNavigate(e.dataItem.processId, e.dataItem.id)
                }
                className="examinations-grid-h"
                rowRender={rowRender}
            >
                <GridNoRecords>
                    <div style={{ width: "100%", textAlign: "left" }}>
                        {isLoadingWork && "Loading..."}
                        {!isLoadingWork && "There is no data available"}
                    </div>
                </GridNoRecords>
                <GridColumn
                    field={"structureType"}
                    title={"Structure Type"}
                    sortable={true}
                    filter={"text"}
                    width={200}
                    columnMenu={ColumnMenuFilter}
                />
                <GridColumn
                    field={"reportType"}
                    title={"Report Type"}
                    sortable={true}
                    filter={"text"}
                    width={200}
                    columnMenu={ColumnMenuFilter}
                />
                <GridColumn
                    field={"elr"}
                    title={"ELR"}
                    sortable={true}
                    filter={"text"}
                    width={110}
                    columnMenu={ColumnMenuFilter}
                />
                <GridColumn
                    field={"miles"}
                    title={"Miles"}
                    sortable={true}
                    filter={"numeric"}
                    width={120}
                    columnMenu={ColumnMenuFilter}
                />
                <GridColumn
                    field={"yards"}
                    title={"Yards"}
                    sortable={true}
                    filter={"numeric"}
                    width={120}
                    columnMenu={ColumnMenuFilter}
                />
                <GridColumn
                    field={"chains"}
                    title={"Chains"}
                    sortable={true}
                    filter={"numeric"}
                    width={130}
                    columnMenu={ColumnMenuFilter}
                />
                <GridColumn
                    field={"structureNumber"}
                    title={"Structure Number"}
                    sortable={true}
                    filter={"text"}
                    width={200}
                    columnMenu={ColumnMenuFilter}
                />
                <GridColumn
                    field={"structureName"}
                    title={"Structure Name"}
                    sortable={true}
                    filter={"text"}
                    width={200}
                    columnMenu={ColumnMenuFilter}
                />
                <GridColumn
                    field={"plannedDateJs"}
                    title={"Planned Date"}
                    sortable={true}
                    filter={"date"}
                    format="{0:dd/MM/yyyy}"
                    width={180}
                    columnMenu={ColumnMenuFilter}
                />
                <GridColumn
                    field={"contractYear"}
                    title={"Contract Year"}
                    sortable={true}
                    filter={"text"}
                    width={180}
                    columnMenu={ColumnMenuFilter}
                />
                <GridColumn
                    field={"status"}
                    title={"Status"}
                    sortable={true}
                    filter={"text"}
                    width={200}
                    columnMenu={ColumnMenuFilter}
                />
                <GridColumn
                    title={"Claim Exam"}
                    cell={(props) => {
                        return MyWorkGridClaimButton({
                            cellprops: props,
                            organisationId,
                        });
                    }}
                    width="150px"
                />
            </Grid>
            {isLoadingWork && <GridLoadingPanel gridRef={wrapperRef} />}
            <CustomPagingFooter
                total={totalCount}
                loading={isLoadingWork}
                dataCount={dataCount}
            />
        </>
    );
};

export default MyWorkGrid;
