import * as React from "react";
import { Button } from "react-bootstrap";
import ClaimExamModal from "./ClaimExamModal";
import ExamNamesConstants from "../Constants/ExamNamesConstants";

interface Props {
    cellprops: any;
    organisationId: string;
}

const unsupportedExamTypes = [
    ExamNamesConstants.PreliminaryAdviceNotification,
    ExamNamesConstants.RapidResponseVehicle,
    ExamNamesConstants.RapidResponseNonVehicle,
    ExamNamesConstants.UnderwaterExam,
    ExamNamesConstants.ScourExam,
    ExamNamesConstants.ScourStageOne,
    ExamNamesConstants.ScourStageOneReview,
    ExamNamesConstants.ScourStageTwo,
    ExamNamesConstants.UnderwaterScourOne,
    ExamNamesConstants.UnderwaterScourOneReview,
    ExamNamesConstants.UnderwaterScourTwo,
    ExamNamesConstants.UnderwaterScourTwoReview,
];

function claimButtonVisible(examType: string, examStatus: string) {
    return !unsupportedExamTypes.includes(examType) && examStatus === "Planned";
}

export const MyWorkGridClaimButton = ({
    cellprops,
    organisationId,
}: Props): JSX.Element => {
    const [assignActive, setAssignActive] = React.useState<boolean>(false);
    if (
        claimButtonVisible(
            cellprops.dataItem.reportType,
            cellprops.dataItem.status,
        )
    ) {
        return (
            <td>
                <Button
                    {...cellprops}
                    variant="primary"
                    type="submit"
                    size="md"
                    className="ml-1  my-1"
                    title="Claim"
                    onClick={() => setAssignActive(true)}
                >
                    Claim
                </Button>

                {assignActive && (
                    <ClaimExamModal
                        onClose={() => {
                            setAssignActive(false);
                        }}
                        processId={cellprops.dataItem.processId}
                        organisationId={organisationId}
                    />
                )}
            </td>
        );
    } else {
        return <td></td>;
    }
};
