import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Formik } from "formik";
import * as React from "react";
import { Button, FormLabel } from "react-bootstrap";
import CoveringLoadingPanel from "../../../../../common/components/CoveringLoadingPanel";
import { useRefreshEngineerDefectsMutation } from "../../query/examinationsReviewQueries";

interface FormValues {
    organisationId: string;
    reviewId: string;
}

interface Props {
    organisationId: string;
    reviewId: string;
    onClose: () => void;
}

const message =
    "Are you sure you want to refresh the Engineer Defects? This action will delete the existing live engineer defects and replace them with the latest defects loaded from the most recent CES data.";

const RefreshDefectsModal: React.FC<Props> = ({
    organisationId,
    reviewId,
    onClose,
}) => {
    const refreshEngineerDefects = useRefreshEngineerDefectsMutation(
        reviewId,
        organisationId,
    );

    const initialValues: FormValues = {
        reviewId: "",
        organisationId: "",
    };

    const onSubmit = async ({ reviewId, organisationId }: FormValues) => {
        refreshEngineerDefects.mutate(
            {
                reviewId,
                organisationId,
            },
            { onSuccess: () => onClose(), onError: () => onClose() },
        );
    };

    return (
        <Dialog
            onClose={onClose}
            title={"Refresh Engineer Defects"}
            width={400}
            contentStyle={{ overflow: "visible" }}
        >
            {refreshEngineerDefects.isLoading && <CoveringLoadingPanel />}

            <Formik onSubmit={onSubmit} initialValues={initialValues}>
                {() => (
                    <Form>
                        <FormLabel>
                            <strong>{message}</strong>
                        </FormLabel>
                        <Button
                            onClick={onClose}
                            variant="secondary"
                            className="mr-1"
                        >
                            Cancel
                        </Button>
                        <Button className="mr-1" type="submit" variant="danger">
                            Refresh
                        </Button>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default RefreshDefectsModal;
