import * as React from "react";
import { TypedGridCellProps } from "../../../types/TypedGridCellProps";
import { FormControlFileTableDataItem } from "./FormControlFileTableDataItem";

interface Props {
    cell: TypedGridCellProps<FormControlFileTableDataItem>;
}

const FormControlFileTableCommentCell: React.FC<Props> = (props) => {
    const { cell } = props;
    const [comment, setComment] = React.useState<string>(cell.dataItem.comment);

    const onChange = (e) => {
        if (cell.onChange) {
            cell.onChange({
                dataIndex: 0,
                dataItem: cell.dataItem,
                field: cell.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            });

            setComment(e.target.value);
        }
    };

    return (
        <textarea
            value={comment}
            onChange={onChange}
            style={{
                width: "100%",
                resize: "vertical",
                border: "none",
            }}
            rows={1}
            readOnly={cell.dataItem.readOnly}
        />
    );
};

export default FormControlFileTableCommentCell;
