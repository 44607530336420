import {
    GridFieldsMap,
    GridFieldType,
} from "../../../../../common/components/table/kendo/GridFieldsMap";
import {
    AssetInfoContentFieldNames,
    PlanningInfoContentFieldNames,
} from "../../../constants/ProcessActivityContentFieldsConsts";

export const gridFieldsMap: GridFieldsMap = {
    processId: {
        field: PlanningInfoContentFieldNames.ProcessId,
        label: "Process Id",
        dataType: "string",
        fieldType: GridFieldType.MultiText,
        width: 300,
    },
    carrsGUID: {
        field: PlanningInfoContentFieldNames.CarrsGuid,
        label: "CARRS GUID",
        dataType: "string",
        fieldType: GridFieldType.MultiText,
        isCustomField: true,
        width: 300,
    },
    processTemplateName: {
        field: "processTemplateName",
        label: "Report Type",
        dataType: "string",
        fieldType: GridFieldType.MultiText,
        isCustomField: true,
        width: 250,
    },
    carrsExamId: {
        field: PlanningInfoContentFieldNames.CarrsExamId,
        label: "CARRS Exam ID",
        dataType: "string",
        fieldType: GridFieldType.Text,
        isCustomField: true,
        width: 150,
        filterable: false, // No filter as this column should always be empty
    },
    contractYear: {
        field: PlanningInfoContentFieldNames.ContractYear,
        label: "Contract Year",
        dataType: "string",
        fieldType: GridFieldType.Text,
        isCustomField: true,
    },
    elr: {
        field: PlanningInfoContentFieldNames.Elr,
        label: "ELR",
        dataType: "string",
        fieldType: GridFieldType.Text,
        isCustomField: true,
    },
    miles: {
        field: PlanningInfoContentFieldNames.Miles,
        label: "Miles",
        dataType: "number",
        fieldType: GridFieldType.Number,
        isCustomField: true,
    },
    yards: {
        field: PlanningInfoContentFieldNames.Yards,
        label: "Yards",
        dataType: "number",
        fieldType: GridFieldType.Number,
        isCustomField: true,
    },
    chains: {
        field: PlanningInfoContentFieldNames.Chains,
        label: "Chains",
        dataType: "string",
        fieldType: GridFieldType.Text,
        isCustomField: true,
        filterable: true,
    },
    structureRefNo: {
        field: AssetInfoContentFieldNames.StructureReferenceNumber,
        label: "Structure Ref No",
        dataType: "string",
        fieldType: GridFieldType.Text,
        isCustomField: true,
    },
};

export const gridFilterOperators = {
    text: [
        {
            text: "grid.filterContainsOperator",
            operator: "contains",
        },
    ],
    date: [
        {
            text: "grid.filterEqOperator",
            operator: "eq",
        },
    ],
    numeric: [
        {
            text: "grid.filterEqOperator",
            operator: "eq",
        },
    ],
};
