import * as React from "react";
import { Breadcrumb, Button, Col, Row } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import CoveringLoadingPanel from "../../../common/components/CoveringLoadingPanel";
import CustomMultiSelect from "../../../common/components/CustomMultiSelect";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { GridColumnDef } from "../../../store/features/assets-grid/assetsGridSlice";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import OrgAssetList from "../containers/OrgAssetList";
import { usePersistedAssetsGridState } from "../hooks/usePersistedAssetsGridState";
import "./asset.scss";
import { useGetOrgByShortNameQuery } from "../../../store/features/organisation/organisation-api-slice";

const defaultColumns: GridColumnDef[] = [
    {
        field: "name",
        title: "Name",
    },
    {
        field: "description",
        title: "Description",
    },
];

const AssetListPage: React.FC<RouteComponentProps<{ orgShortName: string }>> = (
    props,
) => {
    const orgShortName = props.match.params.orgShortName;
    const defaultColumnNames = defaultColumns.map((c) => c.title);

    const { data: organisation, isLoading } = useGetOrgByShortNameQuery(
        orgShortName,
        {
            skip: !orgShortName,
        },
    );

    const canCreateAsset = hasUserOrgPermission(
        organisation.id,
        PermissionConstants.OrgAsset.create,
    ).hasPermission;

    const { templatesSelectProps, columnsSelectProps, clearFilters } =
        usePersistedAssetsGridState(organisation.id, defaultColumns);

    if (isLoading) return <CoveringLoadingPanel />;

    return (
        <>
            <Row className="top10">
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item active>
                            {organisation?.features?.assets?.sideMenuName ??
                                "Assets"}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>
                        {organisation?.features?.assets?.sideMenuName ??
                            "Assets"}
                    </h1>
                </Col>
            </Row>
            <Row className="top10">
                <Col xs="auto">
                    {canCreateAsset && (
                        <Link
                            to={`/${orgShortName}${OrganisationConstants.AssetCreate}`}
                            className="btn btn-primary"
                        >
                            Create new{" "}
                            {organisation?.features?.assets?.createName ??
                                "Asset"}
                        </Link>
                    )}
                </Col>
                <Col className="assets-templates-filters">
                    <CustomMultiSelect
                        {...templatesSelectProps}
                        defaultOptions={[]}
                    />
                    <CustomMultiSelect
                        {...columnsSelectProps}
                        defaultOptions={defaultColumnNames}
                    />
                </Col>
                <Col xs="auto">
                    <Button
                        color="warning"
                        onClick={clearFilters}
                        variant="outline-secondary"
                    >
                        Clear Filters
                    </Button>
                </Col>
            </Row>
            <Row className="top10">
                <Col>
                    <OrgAssetList organisation={organisation} />
                </Col>
            </Row>
        </>
    );
};

export default AssetListPage;
