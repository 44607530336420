import * as React from "react";
import { formatIsoDate } from "../../../helpers/dateTimeHelpers";
import {
    ApprovalResponse,
    ApprovalResponseType,
    ProcessActivityApproval,
} from "../domain/types";

const ApprovalResponseItem: React.FC<{
    data: ApprovalResponse;
    processActivityApproval: ProcessActivityApproval;
}> = (props) => {
    const { data, processActivityApproval } = props;

    let responseType;
    if (data.responseType === 0) {
        responseType =
            processActivityApproval.approvalActionHistoricalDisplayName;
    } else if (data.responseType === 1) {
        responseType =
            processActivityApproval.rejectActionHistoricalDisplayName;
    } else if (data.responseType === 2) {
        responseType = "Reset";
    }

    return (
        <li className="d-flex">
            <span>{data.approver.userEmail}</span>

            <span className="px-1">
                <strong>
                    {responseType ?? ApprovalResponseType[data.responseType]}
                </strong>
            </span>

            <span className="px-1 text-break">
                {data.comments.length > 0 && (
                    <>
                        with comments <strong>{data.comments[0]}</strong>
                    </>
                )}
            </span>

            <span className="flex-shrink-0 ml-auto">
                {formatIsoDate(data.performedAt)}
            </span>
        </li>
    );
};

export default ApprovalResponseItem;
