import { State } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import React from "react";
import CustomPagingFooter from "../../../../../common/components/table/kendo/CustomPagingFooter";
import { GridFieldDef } from "../../../../../common/components/table/kendo/GridFieldsMap";
import GridLoadingPanel from "../../../../../common/components/table/kendo/GridLoadingPanel";
import { resolveSortAndFilterOptions } from "../../../../../common/components/table/kendo/helpers";
import useInfiniteGridProps from "../../../../../common/components/table/kendo/useInfiniteGridProps";
import useInfiniteDataResult from "../../../../../common/hooks/useInfiniteDataResult";
import { useNavigation } from "../../../../../router/useNavigate";
import { gridFieldsMap, gridFilterOperators } from "./gridConfiguration";
import { Organisation } from "../../../../organisation/domain/types";
import { useGetInfiniteExams } from "../../query/examinationsSearchQueries";
import ExamDownload from "./ExamDownload";
import { SimplifiedMenuFilter } from "../../../../../common/components/table/kendo/columnFilters";

const grisColProps = (field: GridFieldDef, index: number) => ({
    key: index,
    field: field.field,
    title: field.label || field.field,
    sortable: !field.isCustomField,
    width: field.width,
});

interface Props {
    organisation: Organisation;
}

const ExamInfiniteGrid: React.FC<Props> = ({ organisation }) => {
    const { navigateToOrgPath: navigateToPath } = useNavigation();
    const wrapperRef = React.createRef<HTMLElement>();
    const [dataState, setDataState] = React.useState<State>({});

    const query = React.useMemo(
        () => ({
            ...resolveSortAndFilterOptions(dataState, gridFieldsMap),
        }),
        [dataState],
    );

    const {
        data: exams,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
    } = useGetInfiniteExams(organisation.id, query);

    const { flatData, totalCount, dataCount } = useInfiniteDataResult(exams);

    const isLoadingProcesses = isFetching || isFetchingNextPage;

    const gridProps = useInfiniteGridProps({
        data: flatData,
        dataState,
        hasNextPage,
        setDataState,
        loading: isLoadingProcesses,
        fetchMore: () => {
            fetchNextPage();
        },
        gridRef: wrapperRef,
    });

    const onNavigate = React.useCallback(
        (processId: string) => {
            navigateToPath(`/:orgShortName/process/${processId}`);
        },
        [navigateToPath],
    );

    const switchColumnType = React.useCallback(
        (field: GridFieldDef, index: number): JSX.Element => {
            return (
                <GridColumn
                    filter={field.filterType}
                    columnMenu={SimplifiedMenuFilter}
                    {...grisColProps(field, index)}
                />
            );
        },
        [],
    );

    return (
        <div
            ref={wrapperRef as React.RefObject<HTMLDivElement>}
            className="examinations-grid-h pt-2"
        >
            <Grid
                {...gridProps}
                reorderable={true}
                resizable={true}
                fixedScroll={true}
                sortable={{
                    allowUnsort: true,
                    mode: "multiple",
                }}
                filterOperators={gridFilterOperators}
                onRowDoubleClick={(e) => onNavigate(e.dataItem.processId)}
                className="examinations-grid-h"
            >
                {}
                <GridNoRecords>There is no data available</GridNoRecords>
                {Object.values(gridFieldsMap).map(switchColumnType)}
                <GridColumn
                    key="actions"
                    field="actions"
                    title="Actions"
                    width={100}
                    filterable={false}
                    cell={ExamDownload}
                />
            </Grid>

            {isLoadingProcesses && <GridLoadingPanel gridRef={wrapperRef} />}
            <CustomPagingFooter
                total={totalCount}
                loading={isLoadingProcesses}
                dataCount={dataCount}
            />
        </div>
    );
};

export default ExamInfiniteGrid;
