import * as React from "react";

import {
    DropDownList,
    DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";

interface Option {
    value: string;
    text: string;
}
const DaysSinceExamFilterCell: React.FC<GridFilterCellProps> = (props) => {
    const hasValue = (value: string): boolean => Boolean(value && value !== "");

    const defaultItem: Option = { text: "", value: "" };
    const [value, setValue] = React.useState<Option>(defaultItem);

    const options: Option[] = [
        { text: "0-7", value: "0,7" },
        { text: "8-14", value: "8,14" },
        { text: "15-28", value: "15,28" },
        { text: "28+", value: "29,10000" },
    ];

    const inRange = (current, { min, max }) =>
        (min === null || current >= min) && (max === null || current <= max);

    const onChange = (event: DropDownListChangeEvent) => {
        const eventHasValue = hasValue(event.target.value);

        if (eventHasValue) {
            const split = event.target.value.value.split(",");
            setValue(event.target.value);
            props.onChange({
                value: {
                    min: eventHasValue ? parseInt(split[0]) : "",
                    max: eventHasValue ? parseInt(split[1]) : "",
                },
                operator: inRange,
                syntheticEvent: event.syntheticEvent,
            });
        }
    };

    const onClearButtonClick = (event) => {
        event.preventDefault();
        props.onChange({
            value: "",
            operator: "",
            syntheticEvent: event,
        });
        setValue(defaultItem);
    };
    return (
        <div className="k-filtercell">
            <div className="k-filtercell-wrapper">
                <DropDownList
                    data={options}
                    onChange={onChange}
                    value={value}
                    defaultItem={defaultItem}
                    textField="text"
                />
                <div className="k-filtercell-operator">
                    <Button
                        title="Clear"
                        disabled={!hasValue(props.value)}
                        onClick={onClearButtonClick}
                        icon="filter-clear"
                    />
                </div>
            </div>
        </div>
    );
};
export default DaysSinceExamFilterCell;
