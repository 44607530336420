import * as React from "react";
import cx from "classnames";
import { Button } from "react-bootstrap";

interface FolderUploadButtonProps {
    onUpload: (files: FileList) => void;
}

const FolderUploadButton: React.FC<FolderUploadButtonProps> = ({
    onUpload,
}) => {
    const handleFolderUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        onUpload(event.target.files);
    };

    return (
        <Button
            variant="outline-secondary"
            className={cx("position-relative", "overflow-hidden")}
        >
            Upload folder
            <input
                type="file"
                className="file-button__input"
                role="button"
                onChange={handleFolderUpload}
                /* @ts-expect-error directory/webkitdirectory custom browser support */
                // eslint-disable-next-line react/no-unknown-property
                directory=""
                webkitdirectory=""
            />
        </Button>
    );
};

export default FolderUploadButton;
