import { TemplateContentFilter } from "../../common/filters/domain/types";
import { ProcessActivity } from "../../process-activity/domain/types";

export enum ApprovalStatus {
    New = 0,
    Pending = 1,
    Approved = 2,
    Rejected = 3,
}

export enum ApprovalResponseType {
    Approved = 0,
    Rejected = 1,
    Reset = 2,
}

export interface Approver {
    userId: string;
    userEmail: string;
}

export interface Approval {
    id?: string;
    approver: Approver;
    status: ApprovalStatus;
    comments: string[];
    updatedAt: string;
}

export interface ApprovalResponse {
    id: string;
    approver: Approver;
    responseType: ApprovalResponseType;
    comments: string[];
    performedAt: string;
}

export interface TargetFile {
    fileName: string;
    storageLocation: string;
    comment: string;
}

export interface TargetLink {
    url: string;
}

export interface ProcessActivityApproval extends ProcessActivity {
    approvals: Approval[];
    approvalResponses: ApprovalResponse[];
    requiresAllApprovals: boolean;
    approversFilter: TemplateContentFilter;
    targetFile: TargetFile;
    targetLink: TargetLink;
    targetType: string;
    approversReadonly: boolean;
    approvalActionDisplayName: string;
    rejectActionDisplayName: string;
    approvalActionHistoricalDisplayName: string;
    rejectActionHistoricalDisplayName: string;
    approversDisplayName: string;
    approvalDisplayName: string;
    addActionDisplayName: string;
}
