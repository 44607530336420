import * as React from "react";
import { FileButton } from "../../../common/components/button/FileButton";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import { UploadFileParams } from "../../../store/features/file/fileApiSlice";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import { Organisation } from "../../organisation/domain/types";
interface FileUploadButtonProps {
    folderId: string;
    organisation: Organisation;
    uploadFile: (params: UploadFileParams) => void;
}

export const FileUploadButton: React.FC<FileUploadButtonProps> = ({
    folderId,
    uploadFile,
    organisation,
}) => {
    const canCreate = hasUserOrgPermission(
        organisation.id,
        PermissionConstants.OrgFilesFile.create,
    ).hasPermission;
    const handleFileSelection = (ev: React.ChangeEvent<HTMLInputElement>) => {
        uploadFile({
            organisationId: organisation.id,
            folderId,
            files: ev.target.files,
        });
    };

    const buttonLabel = `Upload ${
        organisation?.features?.files?.sideMenuName?.toLowerCase().trim() ??
        "files"
    }`;

    return canCreate ? (
        <FileButton onChange={handleFileSelection} multiple={true}>
            {buttonLabel}
        </FileButton>
    ) : null;
};
