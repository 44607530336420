import { process, State } from "@progress/kendo-data-query";
import {
    getSelectedState,
    Grid,
    GridColumn,
    GridNoRecords,
    GridRowDoubleClickEvent,
    GridRowProps,
    GridSelectionChangeEvent,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import classNames from "classnames";
import * as React from "react";
import { Button, ButtonToolbar } from "react-bootstrap";
import { commonFilterOperators } from "../../../../../common/components/table/kendo/columnFilters";
import GridLoadingPanel from "../../../../../common/components/table/kendo/GridLoadingPanel";
import { resolveSortAndFilterOptions } from "../../../../../common/components/table/kendo/helpers";
import PermissionConstants from "../../../../../common/permissions/PermissionConstants";
import { AppRoutes } from "../../../../../router/AppRoutes";
import { useNavigation } from "../../../../../router/useNavigate";
import { hasUserOrgPermission } from "../../../../../store/features/user/user-api-slice";
import {
    TemplateContentFilter,
    TemplateContentFilterOperator,
    TemplateContentFilterProperty,
} from "../../../../common/filters/domain/types";
import {
    AssetInfoContentFieldNames,
    PlanningInfoContentFieldNames,
} from "../../../constants/ProcessActivityContentFieldsConsts";
import { ExaminationReview } from "../../domain/examinationsReview";
import {
    useAssignExaminationReviewsMutation,
    useGetExaminationReviews,
} from "../../query/examinationsReviewQueries";
import AssignModal from "../AssignModal";
import "./examinationReviewsGrid.scss";
import { examinationReviewsGridFieldsMap } from "./examinationReviewsGridFieldsMap";
import { BetweenDatesFilterCell } from "./BetweenDatesFilterCell";
import { SelectedState } from "../../../../../common/components/table/kendo/SelectedState";
import DaysSinceExamFilterCell from "./DaysSinceExamFilterCell";
import { AreaMultiSelectFilterCell } from "./AreaMultiSelectFilterCell";
import { CarrsExamIdExistsFilterCell } from "./CarrsExamIdExistsFilterCell";

const usersFilter: TemplateContentFilter = {
    property: TemplateContentFilterProperty.UserGroup,
    operator: TemplateContentFilterOperator.Any,
    values: ["Engineer"],
};

interface Props {
    organisationId: string;
}

const ExaminationReviewsGrid: React.FC<Props> = ({ organisationId }) => {
    const SELECTED_FIELD = "selected";
    const DATA_ITEM_KEY = "id";
    const idGetter = getter(DATA_ITEM_KEY);

    const { navigateToWithOrg } = useNavigation();
    const wrapperRef = React.createRef<HTMLElement>();
    const [selectedState, setSelectedState] = React.useState<SelectedState>({});
    const [assignActive, setAssignActive] = React.useState<boolean>(false);

    const canAssignUsers = hasUserOrgPermission(
        organisationId,
        PermissionConstants.ExaminationReviews.assign,
    ).hasPermission;

    const [dataState, setDataState] = React.useState<State>({
        sort: [
            {
                field: "examId",
                dir: "desc",
            },
        ],
    });

    const query = React.useMemo(
        () =>
            resolveSortAndFilterOptions(
                dataState,
                examinationReviewsGridFieldsMap,
            ),
        [dataState],
    );

    const {
        data: examinations,
        isLoading,
        refetch,
    } = useGetExaminationReviews(organisationId, query);

    const flatData = React.useMemo(
        () =>
            examinations?.map((dataItem: ExaminationReview) =>
                Object.assign({ selected: false }, dataItem),
            ) ?? [],
        [examinations],
    );

    const onSelectionChange = React.useCallback(
        (event: GridSelectionChangeEvent) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });

            setSelectedState(newSelectedState);
        },
        [selectedState],
    );

    const result = process(
        flatData
            .map((item) => ({
                ...item,
                [SELECTED_FIELD]: selectedState[idGetter(item)],
            }))
            .slice(0),
        dataState,
    );

    const assignMutation = useAssignExaminationReviewsMutation(organisationId);

    const isLoadingReviews = isLoading;

    const rowRender = (
        row: React.ReactElement<HTMLTableRowElement>,
        rowProps: GridRowProps,
    ) => {
        const item = rowProps.dataItem;
        const trProps = {
            ...row.props,
            className: classNames(
                {
                    "table-danger":
                        (item.daysSinceExamDate >= 28 && !item.selected) ??
                        false,
                    "table-warning":
                        (item.daysSinceExamDate >= 21 &&
                            item.daysSinceExamDate < 28) ??
                        false,
                },
                row.props.className,
            ),
        };
        return React.cloneElement(row, { ...trProps }, row.props.children);
    };

    const onNavigate = React.useCallback(
        (e: GridRowDoubleClickEvent) => {
            navigateToWithOrg(AppRoutes.EngineerReviewDetails, {
                reviewId: e.dataItem.id,
            });
        },
        [navigateToWithOrg],
    );

    const multiSelectAreaFilter = (props) => {
        return (
            <AreaMultiSelectFilterCell {...props} examinations={examinations} />
        );
    };

    return (
        <div>
            {assignActive && (
                <AssignModal
                    title="Assign Engineer"
                    examinations={flatData.filter(
                        (item) => selectedState[idGetter(item)],
                    )}
                    organisationId={organisationId}
                    usersFilter={usersFilter}
                    assignMutation={assignMutation}
                    onClose={() => {
                        setAssignActive(false);
                        refetch();
                    }}
                />
            )}
            <ButtonToolbar>
                <Button
                    variant="primary"
                    onClick={() => {
                        setAssignActive(true);
                    }}
                    disabled={
                        flatData.filter((item) => selectedState[idGetter(item)])
                            .length === 0 && canAssignUsers
                    }
                >
                    Assign Engineer
                </Button>
            </ButtonToolbar>
            <div
                ref={wrapperRef as React.RefObject<HTMLDivElement>}
                className="examinations-grid-h pt-2"
            >
                <Grid
                    sortable={true}
                    data={result}
                    {...dataState}
                    selectable={{
                        enabled: true,
                        mode: "multiple",
                        cell: false,
                    }}
                    fixedScroll={true}
                    reorderable={true}
                    resizable={true}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    onSelectionChange={onSelectionChange}
                    onDataStateChange={(e) => {
                        setDataState(e.dataState);
                    }}
                    filterable
                    filterOperators={commonFilterOperators}
                    onRowDoubleClick={onNavigate}
                    className="examinations-grid-h"
                    rowRender={rowRender}
                >
                    <GridNoRecords>
                        <div style={{ width: "100%", textAlign: "left" }}>
                            {isLoading && "Loading..."}
                            {!isLoading && "There is no data available"}
                        </div>
                    </GridNoRecords>
                    <GridColumn
                        key={"area"}
                        field={"area"}
                        title={"Area"}
                        width={300}
                        filterCell={multiSelectAreaFilter}
                    />
                    <GridColumn
                        key={PlanningInfoContentFieldNames.ContractYear}
                        field={PlanningInfoContentFieldNames.ContractYear}
                        title={"Contract Year"}
                        width={200}
                    />
                    <GridColumn
                        key={PlanningInfoContentFieldNames.Elr}
                        field={PlanningInfoContentFieldNames.Elr}
                        title={"ELR"}
                        width={180}
                    />
                    <GridColumn
                        key={PlanningInfoContentFieldNames.Miles}
                        field={PlanningInfoContentFieldNames.Miles}
                        title={"Miles"}
                        width={180}
                    />
                    <GridColumn
                        key={PlanningInfoContentFieldNames.Yards}
                        field={PlanningInfoContentFieldNames.Yards}
                        title={"Yards"}
                        width={180}
                    />
                    <GridColumn
                        key={PlanningInfoContentFieldNames.Chains}
                        field={PlanningInfoContentFieldNames.Chains}
                        title={"Chains"}
                        width={180}
                    />
                    <GridColumn
                        key={AssetInfoContentFieldNames.StructureName}
                        field={AssetInfoContentFieldNames.StructureName}
                        title={"Structure Name"}
                        width={300}
                    />
                    <GridColumn
                        key={"structureReferenceNumber"}
                        field={"structureReferenceNumber"}
                        title={"Structure Ref"}
                        width={300}
                    />
                    <GridColumn
                        key={"examType"}
                        field={"examType"}
                        title={"Exam Type"}
                        width={300}
                    />
                    <GridColumn
                        key={"examinationDate"}
                        field={"examinationDate"}
                        title={"Examination Date"}
                        width={300}
                        filter={"date"}
                        format="{0:dd/MM/yyyy}"
                        filterCell={BetweenDatesFilterCell}
                    />
                    <GridColumn
                        key={"defectsTracked"}
                        field={"defectsTracked"}
                        title={"Defects Tracked"}
                        width={180}
                    />
                    <GridColumn
                        key={"assignedExaminer"}
                        field={"assignedExaminer"}
                        title={"Assigned Examiner"}
                        width={300}
                    />
                    <GridColumn
                        key={"assignedEngineer"}
                        field={"assignedEngineer"}
                        title={"Assigned Engineer"}
                        width={300}
                    />
                    <GridColumn
                        key={"assetType"}
                        field={"assetType"}
                        title={"Asset Type"}
                        width={150}
                    />
                    <GridColumn
                        key={"carrsExamId"}
                        field={"carrsExamId"}
                        title={"CARRS Exam Id"}
                        width={180}
                        filterCell={CarrsExamIdExistsFilterCell}
                    />
                    <GridColumn
                        key={"daysSinceExamDate"}
                        field={"daysSinceExamDate"}
                        title={"Days Since Exam"}
                        width={180}
                        filter={"numeric"}
                        filterCell={DaysSinceExamFilterCell}
                    />
                    <GridColumn
                        key={"state"}
                        field={"state"}
                        title={"State"}
                        width={200}
                    />
                </Grid>
                {isLoadingReviews && <GridLoadingPanel gridRef={wrapperRef} />}
            </div>
        </div>
    );
};

export default ExaminationReviewsGrid;
