import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Formik } from "formik";
import * as React from "react";
import { Button } from "react-bootstrap";
import * as yup from "yup";
import CoveringLoadingPanel from "../../../../common/components/CoveringLoadingPanel";
import { TextInput } from "../../../../common/components/form";
import { Examination } from "../domain/examination";
import { useCancelExaminationsMutation } from "../query/examinationsPlanningQueries";

interface FormValues {
    nrCrNumber: string;
    supplementaryInfo: string;
}

interface Props {
    examinations: Examination[];
    organisationId: string;
    onClose: () => void;
    isAllNonInstructedProcessNames: boolean;
}

const CancelModal: React.FC<Props> = ({
    examinations,
    organisationId,
    onClose,
    isAllNonInstructedProcessNames,
}) => {
    const cancelExaminations = useCancelExaminationsMutation(organisationId);

    const processIds = React.useMemo(
        () => examinations.map((e) => e.processId),
        [examinations],
    );

    const validation = isAllNonInstructedProcessNames
        ? null
        : yup.object({
              nrCrNumber: yup.string().required("Required"),
              supplementaryInfo: yup.string().required("Required"),
          });

    const initialValues: FormValues = {
        nrCrNumber: "",
        supplementaryInfo: "",
    };

    const onSubmit = async ({ nrCrNumber, supplementaryInfo }: FormValues) => {
        cancelExaminations.mutate(
            {
                processIds: processIds,
                nrCrNumber,
                supplementaryInfo,
            },
            { onSuccess: () => onClose(), onError: () => onClose() },
        );
    };

    return (
        <Dialog title="Cancel Examinations" onClose={onClose} width={400}>
            {cancelExaminations.isLoading && <CoveringLoadingPanel />}
            <Formik
                validationSchema={validation}
                onSubmit={onSubmit}
                initialValues={initialValues}
            >
                {() => (
                    <Form>
                        {!isAllNonInstructedProcessNames && (
                            <TextInput label="NR CR Number" name="nrCrNumber" />
                        )}

                        {!isAllNonInstructedProcessNames && (
                            <TextInput
                                label="Supplementary Info"
                                name="supplementaryInfo"
                            />
                        )}

                        <DialogActionsBar>
                            <Button
                                type="button"
                                variant="outline-secondary"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button type="submit" variant="primary">
                                Submit
                            </Button>
                        </DialogActionsBar>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default CancelModal;
