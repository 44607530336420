import { useField } from "formik";
import React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import FormControlError from "../../../../common/components/dynamic-form/FormControlError";
import { AssetWithPropsModel } from "../../../../store/features/asset/asset-api-slice";
import AssetPropertiesConstants from "../../asset/AssetPropertiesConstants";
import AddAssetModal from "./modals/AddAssetModal";

const SelectAssetFormInput: React.FC<{ organisationId: string }> = ({
    organisationId,
}) => {
    const [addActive, setAddActive] = React.useState<boolean>(false);

    const [, , assetIdHelpers] = useField("assetId");
    const [elrField, , elrHelpers] = useField("apcAssetDetails.elr");
    const [milesField, , milesHelpers] = useField("apcAssetDetails.miles");
    const [yardsField, , yardsHelpers] = useField("apcAssetDetails.yards");
    const [chainsField, , chainsHelpers] = useField("apcAssetDetails.chains");
    const [assetTypeField, , assetTypeHelpers] = useField(
        "apcAssetDetails.assetType",
    );
    const [structureRefNumField, , structureRefNumHelpers] = useField(
        "apcAssetDetails.structureRefNum",
    );
    const [structureNameField, , structureNameHelpers] = useField(
        "apcAssetDetails.structureName",
    );

    const AssetModelOnClose = React.useCallback(() => {
        setAddActive(false);
    }, []);

    const AssetModelOpen = React.useCallback(() => {
        setAddActive(true);
    }, []);

    const handleSubmitOfAssetModal = React.useCallback(
        (assets: AssetWithPropsModel[]) => {
            assetIdHelpers.setValue(assets[0].id);
            elrHelpers.setValue(
                assets[0].properties.find(function (prop) {
                    return prop.id === AssetPropertiesConstants.Elr;
                }).value,
            );
            milesHelpers.setValue(
                assets[0].properties.find(function (prop) {
                    return prop.id === AssetPropertiesConstants.Miles;
                }).value,
            );
            yardsHelpers.setValue(
                assets[0].properties.find(function (prop) {
                    return prop.id === AssetPropertiesConstants.Yards;
                }).value,
            );
            chainsHelpers.setValue(
                assets[0].properties.find(function (prop) {
                    return prop.id === AssetPropertiesConstants.Chains;
                }).value,
            );
            assetTypeHelpers.setValue(
                assets[0].properties.find(function (prop) {
                    return prop.id === AssetPropertiesConstants.AssetType;
                }).value,
            );
            structureRefNumHelpers.setValue(
                assets[0].properties.find(function (prop) {
                    return prop.id === AssetPropertiesConstants.StructureRefNum;
                }).value,
            );
            structureNameHelpers.setValue(
                assets[0].properties.find(function (prop) {
                    return prop.id === AssetPropertiesConstants.StructureName;
                }).value,
            );
        },
        [
            assetIdHelpers,
            assetTypeHelpers,
            chainsHelpers,
            elrHelpers,
            milesHelpers,
            structureNameHelpers,
            structureRefNumHelpers,
            yardsHelpers,
        ],
    );

    return (
        <>
            {addActive && (
                <AddAssetModal
                    organisationId={organisationId}
                    onClose={AssetModelOnClose}
                    onSubmit={handleSubmitOfAssetModal}
                />
            )}
            <Row>
                <Col>
                    <Form.Group>
                        <Button
                            variant="secondary"
                            type="button"
                            onClick={AssetModelOpen}
                        >
                            Select Asset
                        </Button>
                        <FormControlError field={"assetId"} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={2} lg={1}>
                    <Form.Group>
                        <Form.Label>{"ELR"}</Form.Label>
                        <Form.Control
                            type="text"
                            name={elrField.name}
                            readOnly={true}
                            value={elrField.value}
                        />
                    </Form.Group>
                </Col>
                <Col md={2} lg={1}>
                    <Form.Group>
                        <Form.Label>{"Miles"}</Form.Label>
                        <Form.Control
                            type="text"
                            name={milesField.name}
                            readOnly={true}
                            value={milesField.value}
                        />
                    </Form.Group>
                </Col>
                <Col md={2} lg={1}>
                    <Form.Group>
                        <Form.Label>{"Yards"}</Form.Label>
                        <Form.Control
                            type="text"
                            name={yardsField.name}
                            readOnly={true}
                            value={yardsField.value}
                        />
                    </Form.Group>
                </Col>
                <Col md={2} lg={1}>
                    <Form.Group>
                        <Form.Label>{"Chains"}</Form.Label>
                        <Form.Control
                            type="text"
                            name={chainsField.name}
                            readOnly={true}
                            value={chainsField.value}
                        />
                    </Form.Group>
                </Col>
                <Col md={2} lg={1}>
                    <Form.Group>
                        <Form.Label>{"Asset Type"}</Form.Label>
                        <Form.Control
                            type="text"
                            name={assetTypeField.name}
                            readOnly={true}
                            value={assetTypeField.value}
                        />
                    </Form.Group>
                </Col>
                <Col md={6} lg={3}>
                    <Form.Group>
                        <Form.Label>{"Structure Ref No"}</Form.Label>
                        <Form.Control
                            type="text"
                            name={structureRefNumField.name}
                            readOnly={true}
                            value={structureRefNumField.value}
                        />
                    </Form.Group>
                </Col>
                <Col md={8}>
                    <Form.Group>
                        <Form.Label>{"Structure Name"}</Form.Label>
                        <Form.Control
                            type="text"
                            name={structureNameField.name}
                            readOnly={true}
                            value={structureNameField.value}
                        />
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
};

export default SelectAssetFormInput;
