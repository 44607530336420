import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import * as React from "react";
import { Button } from "react-bootstrap";
import CoveringLoadingPanel from "../../../../../common/components/CoveringLoadingPanel";
import useGridProps from "../../../../../common/components/table/kendo/useGridProps";
import { GridSelectionMode } from "../../../../../common/components/table/kendo/useGridSelectableProps";
import { ApprovedExam } from "../domain/approvedExam";
import { useGetApprovedExamsQuery } from "../queries/approvedExamsQueries";
import ReturnReportDialog from "./ReturnReportDialog";

type Props = {
    organisationId: string;
};

const ApprovedExamsGrid: React.FC<Props> = ({ organisationId }) => {
    const [showReturnProcessDialog, setShowReturnProcessDialog] =
        React.useState(false);
    const [selectedProcess, setSelectedProcess] =
        React.useState<ApprovedExam>(null);

    const { data, isLoading } = useGetApprovedExamsQuery(organisationId);

    const { gridProps } = useGridProps(data, {
        selectable: {
            config: {
                enabled: true,
                mode: GridSelectionMode.Single,
            },
            onSelectChange: (newSelected) => {
                setSelectedProcess(newSelected[0]);
            },
        },
    });

    if (isLoading) return <CoveringLoadingPanel />;

    return (
        <>
            <Grid
                {...gridProps}
                fixedScroll={true}
                reorderable={true}
                resizable={true}
                sortable={true}
                scrollable="scrollable"
                filterable
                className="mt-3"
            >
                <GridToolbar>
                    <Button
                        variant="primary"
                        onClick={() => setShowReturnProcessDialog(true)}
                        disabled={!selectedProcess?.id}
                    >
                        Return to Engineer
                    </Button>
                </GridToolbar>
                <GridColumn field="name" title="Name" width="400" />
                <GridColumn field="id" title="Process ID" width="350" />
                <GridColumn
                    field="contractYear"
                    title="Contract Year"
                    width="200"
                />
                <GridColumn field="elr" title="ELR" width="150" />
                <GridColumn
                    field="miles"
                    title="Miles"
                    width="150"
                    filter={"numeric"}
                />
                <GridColumn
                    field="yards"
                    title="Yards"
                    width="150"
                    filter={"numeric"}
                />
                <GridColumn field="chains" title="Chains" width="150" />
                <GridColumn
                    field="structureRefNum"
                    title="Structure Ref No"
                    width="200"
                />
                <GridColumn field="state" title="State" width="200" />
                <GridColumn field="type" title="Exam Type" width="200" />
                <GridColumn
                    field="examDate"
                    title="Exam Date"
                    width="250"
                    filter={"date"}
                    format="{0:dd/MM/yyyy}"
                />
                <GridColumn
                    field="carrsExamId"
                    title="Carrs Exam Id"
                    width="200"
                />
                <GridColumn
                    field="batchName"
                    title="Batch Number"
                    width="200"
                />
            </Grid>
            {showReturnProcessDialog && (
                <ReturnReportDialog
                    onClose={() => setShowReturnProcessDialog(false)}
                    organisationId={organisationId}
                    processId={selectedProcess.id}
                />
            )}
        </>
    );
};

export default ApprovedExamsGrid;
