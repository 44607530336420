import { AuditAction } from "../../../common/types/AuditAction";
import { CommonFeature } from "../../../common/types/CommonFeature";
import { TemplateContentFilter } from "../../common/filters/domain/types";
import { User } from "../../organisation/domain/organisation";

export const enum ProcessActivityType {
    List = "list",
    Group = "group",
    Form = "form",
    Approval = "approval",
    ArtefactGeneration = "artefact-generation",
}

export const enum ActionButtonEndpointTarget {
    Client = "client",
    Core = "core",
}

export interface AssetLink {
    enabled: boolean;
    multiple: boolean;
    editable: boolean;
}

export interface ProcessActivityPreview {
    id: string;
    processId: string;
    parentActivityId?: string;
    type: string;
}

export interface ProcessActivity {
    id: string;
    processId: string;
    organisationId: string;
    parentActivityId?: string;
    displayName: string;
    name: string;
    description: string;
    type: ProcessActivityType;
    required: boolean;
    completedAt: string;
    completedBy: string;
    completedById: string;
    notApplicableFeature: NotApplicableFeature;
    renameEnabled: boolean;
    assignedUsers: User[];
    auditActions: AuditAction[];
    path: ProcessActivityPath[];
    scheduledStart?: string;
    scheduledEnd?: string;
    parentProcessId: string;
    parentProcessName: string;
    processName: string;
    scheduleEnabled: boolean;
    scheduleEditable: boolean;
    navigation: ProcessActivityNavigation;
    statusType: ProcessActivityStatusType;
    statusTypeData: StatusTypeData;
    userAssignment: UserAssignment;
    assetLink: AssetLink;
    alerts: ProcessActivityAlert[];
    readOnly: boolean;
    canDelete: boolean;
    confirmation: boolean;
    customActionButtons: CustomActionButton[];
}

export interface CustomActionButton {
    label: string;
    variant: string;
    prompt: ActionButtonPrompt;
    endpoint: ActionButtonEndpoint;
}

export interface ActionButtonPrompt {
    description: string;
    title: string;
    variant: string;
}

export interface ActionButtonEndpoint {
    url: string;
    target: ActionButtonEndpointTarget;
}

export interface ProcessActivityAlert {
    displayType: string;
    type: string;
    title: string;
    message: string;
    buttons?: ProcessActivityAlertButtonModel[];
}
export interface ProcessActivityAlertButtonModel {
    type: string;
    title: string;
    actionType: string;
    actionParams: string[];
}

export interface StatusTypeData {
    reason: string;
    comment: string;
}

export interface ProcessActivityTypeModel {
    type: string;
}

export interface ProcessActivityPath {
    pathPartId: string;
    text: string;
}

export interface ProcessActivityNavigation {
    previousActivityId: string;
    nextActivityId: string;
}

export interface ProcessActivityNavigator {
    id: string;
    name: string;
    status: number;
}

export interface ProcessActivityNavigators {
    parentName: string;
    navigators: ProcessActivityNavigator[];
}

export enum ProcessActivityStatusType {
    Open = "Open",
    Required = "Required",
    Complete = "Complete",
    NotApplicable = "NotApplicable",
    Disabled = "Disabled",
}

export interface NotApplicableFeature extends CommonFeature {
    reasons: string[];
}

export interface UserAssignment extends CommonFeature {
    isMulti: boolean;
    filter: TemplateContentFilter;
    editable: boolean;
}
