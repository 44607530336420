import * as React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import useViewPermissions from "../../../common/hooks/useViewPermissions";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import ProcessConstants from "../../../Constants/ProcessConstants";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";
import { Process } from "../../process/domain/types";
import { ProcessActivity } from "../domain/types";
import { Organisation } from "../../organisation/domain/types";
interface Props {
    process: Process;
    activity: ProcessActivity;
    organisation: Organisation;
}

const ProcessActivityBreadcrumb: React.FC<Props> = ({
    process,
    activity,
    organisation,
}) => {
    const { organisationId } = useOrganisationId();
    const { canViewProcesses } = useViewPermissions(organisationId);

    return (
        <Breadcrumb className="mt-2">
            {canViewProcesses && (
                <Breadcrumb.Item linkAs={"span"}>
                    <Link
                        to={`/${organisation.shortName}${OrganisationConstants.Process}`}
                    >
                        {organisation?.features?.process?.createName ??
                            "Process"}
                    </Link>
                </Breadcrumb.Item>
            )}
            <Breadcrumb.Item linkAs={"span"}>
                <Link
                    to={`/${organisation.shortName}${OrganisationConstants.Process}/${process?.id}`}
                >
                    {process?.name ?? ""}
                </Link>
            </Breadcrumb.Item>
            {activity?.path
                ? activity?.path.map((a) => (
                      <Breadcrumb.Item linkAs={"span"} key={a.pathPartId}>
                          <Link
                              to={`/${organisation.shortName}${OrganisationConstants.Process}/${process?.id}${ProcessConstants.Activity}/${a.pathPartId}`}
                          >
                              {a.text}
                          </Link>
                      </Breadcrumb.Item>
                  ))
                : null}
            <Breadcrumb.Item active>{activity?.displayName}</Breadcrumb.Item>
        </Breadcrumb>
    );
};

export default ProcessActivityBreadcrumb;
