import { IntlProvider } from "@progress/kendo-react-intl";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import * as React from "react";
import { Route, Switch } from "react-router";
import { NotificationsConnection } from "./common/components/notifications-connection/NotificationsConnection";
import Layout from "./common/layout/Layout";
import OrganisationLayout from "./common/layout/OrganisationLayout";
import { Role } from "./common/permissions/Role";
import { TemplateTypes } from "./common/types/TemplateTypes";
import AdminConstants from "./Constants/AdminConstants";
import OrganisationConstants from "./Constants/OrganisationConstants";
import AdminHomePage from "./modules/admin/home/pages/AdminHomePage";
import RoleAdminPage from "./modules/admin/role/pages/RoleAdminPage";
import RolesAdminPage from "./modules/admin/role/pages/RolesAdminPage";
import UsersAdminPage from "./modules/admin/user/pages/UsersAdminPage";
import AssetCreatePage from "./modules/asset/pages/AssetCreatePage";
import AssetListPage from "./modules/asset/pages/AssetListPage";
import AssetViewPage from "./modules/asset/pages/AssetViewPage";
import UpdateUserRedirectPage from "./modules/authorization/pages/UpdateUserRedirectPage";
import BatchingDetailsPage from "./modules/cefa/batching/pages/BatchingDetailsPage";
import BatchingOverviewPage from "./modules/cefa/batching/pages/BatchingOverviewPage";
import CommercialReportExportPage from "./modules/cefa/commercial-report/pages/CommercialReportExportPage";
import ExamRequestsPage from "./modules/cefa/exam-requests/pages/ExamRequestsPage";
import DeliveredExamsReportPage from "./modules/cefa/exam-tools/approved-exams/pages/ApprovedExamsReportPage";
import MissingCarrsExamToolPage from "./modules/cefa/exam-tools/missing-carrs-exam/pages/MissingCarrsExamToolPage";
import ExaminationReviewDetailsPage from "./modules/cefa/examinations/pages/ExaminationReviewDetailsPage";
import ExaminationReviewsPage from "./modules/cefa/examinations/pages/ExaminationReviewsPage";
import ExaminationSchedulesPage from "./modules/cefa/examinations/pages/ExaminationSchedulesPage";
import MyWorkPage from "./modules/cefa/examinations/pages/MyWorkPage";
import ProgressReportExportPage from "./modules/cefa/progress-report/pages/ProgressReportExportPage";
import PerformanceReportExportPage from "./modules/cefa/performance-report/pages/PerformanceReportExportPage";
import ReactiveExamCreatorPage from "./modules/cefa/reactive-exam-creator/pages/ReactiveExamCreatorPage";
import WorkbankUploadPage from "./modules/cefa/workbank/pages/WorkbankUploadPage";
import { FileDetailsPage } from "./modules/files/pages/FileDetailsPage";
import FileSearchPage from "./modules/files/pages/FileSearchPage";
import { FolderPage } from "./modules/files/pages/FolderPage";
import { FolderRootPage } from "./modules/files/pages/FolderRootPage";
import InvitationPage from "./modules/invitation/pages/InvitationPage";
import InvitationRedirectPage from "./modules/invitation/pages/InvitationRedirectPage";
import NotificationToastsWrapper from "./modules/notifications/containers/NotificationToastsWrapper";
import InvitationManagement from "./modules/organisation/containers/invitation/InvitationManagement";
import OrganisationCreatePage from "./modules/organisation/pages/OrganisationCreatePage";
import OrganisationHomeRedirectPage from "./modules/organisation/pages/OrganisationHomeRedirectPage";
import OrganisationManagePage from "./modules/organisation/pages/OrganisationManagePage";
import OrganisationsPage from "./modules/organisation/pages/OrganisationsPage";
import ProcessActivityPage from "./modules/process-activity/pages/ProcessActivityPage";
import ReportTemplateCreatePage from "./modules/process-template-reports/pages/ReportTemplateCreatePage";
import ReportTemplateViewPage from "./modules/process-template-reports/pages/ReportTemplateViewPage";
import ProcessTemplateCreatePage from "./modules/process-template/pages/ProcessTemplateCreatePage";
import ProcessTemplateViewPage from "./modules/process-template/pages/ProcessTemplateViewPage";
import MyAssignedPage from "./modules/process/pages/MyAssignedPage";
import ProcessCreatePage from "./modules/process/pages/ProcessCreatePage";
import ProcessesListPage from "./modules/process/pages/ProcessesListPage";
import ProcessPage from "./modules/process/pages/ProcessPage";
import OrgReportCreatePage from "./modules/report/pages/OrgReportCreatePage";
import OrgReportManagePage from "./modules/report/pages/OrgReportManagePage";
import OrgReportsListPage from "./modules/report/pages/OrgReportsListPage";
import OrgSchedulerListPage from "./modules/scheduler/pages/OrgSchedulerListPage";
import OrgReportsInspectionsPage from "./modules/sbim/pages/OrgReportsInspectionsPage";
import OrgReportsSiteWorksPage from "./modules/sbim/pages/OrgReportsSiteWorksPage";

import SsowDataExportPage from "./modules/ssow/dataexport/pages/SsowDataExportPage";
import TemplateCreatePage from "./modules/template/pages/TemplateCreatePage";
import TemplateViewPage from "./modules/template/pages/TemplateViewPage";
import { DefectDetailsPage } from "./modules/sbim/defects/pages/DefectDetailsPage";
import { UserGroupCreatePage } from "./modules/user-management/pages/UserGroupCreatePage";
import UserGroupManagePage from "./modules/user-management/pages/UserGroupManagePage";
import { UserGroupPage } from "./modules/user-management/pages/UserGroupPage";
import UsersManagementPage from "./modules/user-management/pages/UsersManagementPage";
import { AppRoutes } from "./router/AppRoutes";
import AuthorizeRoute from "./router/AuthorizeRoute";
import useIsClient from "./common/hooks/useIsClient";
import CefaConstants from "./modules/cefa/constants/CefaConstants";
import SsowConstants from "./modules/ssow/Constants/SsowConstants";
import SbimConstants from "./modules/sbim/constants/SbimConstants";
import HelpSectionPage from "./modules/help-section/pages/HelpSectionPage";
import OrgHelpListPage from "./modules/help-section/pages/OrgHelpListPage";
import ApcRoutes from "./modules/cefa/apc/components/ApcRoutes";
import OrgDefectsReportPage from "./modules/sbim/defect-report/pages/OrgReportsDefectsPage";
import OrganisationOverviewPage from "./modules/sbim/organisation-overview/pages/OrganisationOverviewPage";
import ExamManagementTool from "./modules/cefa/exam-tools/exam-management/pages/ExamManagementPage";
import ExaminationSearchPage from "./modules/cefa/examinations/pages/ExaminationSearchPage";
export interface MatchParams {
    orgShortName: string;
}

const CefaWorkbankRoutes: React.FC = () => {
    return (
        <Switch>
            <AuthorizeRoute
                path={AppRoutes.Workbank.path}
                component={WorkbankUploadPage}
            />
        </Switch>
    );
};

const CefaBatchingRoutes: React.FC = () => {
    return (
        <Switch>
            <AuthorizeRoute
                path={AppRoutes.BatchingDetails.path}
                component={BatchingDetailsPage}
            />
            <AuthorizeRoute
                path={AppRoutes.Batching.path}
                component={BatchingOverviewPage}
            />
        </Switch>
    );
};

const CefaEngineerReviewRoutes: React.FC = () => {
    return (
        <Switch>
            <AuthorizeRoute
                path={AppRoutes.EngineerReviewDetails.path}
                component={ExaminationReviewDetailsPage}
            />
            <AuthorizeRoute
                path={AppRoutes.EngineerReview.path}
                component={ExaminationReviewsPage}
            />
        </Switch>
    );
};

const CefaReportCommercialExport: React.FC = () => {
    return (
        <Switch>
            <AuthorizeRoute
                path={AppRoutes.CommercialReport.path}
                component={CommercialReportExportPage}
            />
        </Switch>
    );
};

const CefaReportProgressExport: React.FC = () => {
    return (
        <Switch>
            <AuthorizeRoute
                path={AppRoutes.ProgressReportExport.path}
                component={ProgressReportExportPage}
            />
        </Switch>
    );
};

const CefaReportPerformanceExport: React.FC = () => {
    return (
        <Switch>
            <AuthorizeRoute
                path={AppRoutes.PerformanceReportExport.path}
                component={PerformanceReportExportPage}
            />
        </Switch>
    );
};

const CefaReactiveExamCreator: React.FC = () => {
    return (
        <Switch>
            <AuthorizeRoute
                path={AppRoutes.ReactiveExamCreator.path}
                component={ReactiveExamCreatorPage}
            />
        </Switch>
    );
};

const SevernOrgReportRoutes: React.FC = () => {
    const { inspections, siteWorks, defectReport } = OrganisationConstants;
    return (
        <>
            <AuthorizeRoute
                path={`/:orgShortName${inspections}`}
                component={OrgReportsInspectionsPage}
            />
            <AuthorizeRoute
                path={`/:orgShortName${siteWorks}`}
                component={OrgReportsSiteWorksPage}
            />
            <AuthorizeRoute
                path={`/:orgShortName${defectReport}`}
                component={OrgDefectsReportPage}
            />
        </>
    );
};

const SsowDataExport: React.FC = () => {
    return (
        <Switch>
            <AuthorizeRoute
                path={AppRoutes.SsowDataExport.path}
                component={SsowDataExportPage}
            />
        </Switch>
    );
};

const OrganisationOverview: React.FC = () => {
    return (
        <Switch>
            <AuthorizeRoute
                path={AppRoutes.OrganisationOverview.path}
                component={OrganisationOverviewPage}
            />
        </Switch>
    );
};

const OrgReportRoutes: React.FC = () => {
    const { Report, ReportCreate } = OrganisationConstants;

    return (
        <Switch>
            <AuthorizeRoute
                path={`/:orgShortName${ReportCreate}`}
                component={OrgReportCreatePage}
            />
            <SevernOrgReportRoutes />
            <AuthorizeRoute
                path={`/:orgShortName${Report}/:id`}
                component={OrgReportManagePage}
            />
            <AuthorizeRoute
                path={`/:orgShortName${Report}`}
                component={OrgReportsListPage}
            />
        </Switch>
    );
};

const OrgSchedulerRoutes: React.FC = () => {
    return (
        <Switch>
            <AuthorizeRoute
                path={AppRoutes.SchedulerFrom.path}
                component={OrgSchedulerListPage}
            />
            <AuthorizeRoute
                path={AppRoutes.Scheduler.path}
                component={OrgSchedulerListPage}
            />
        </Switch>
    );
};

const OrgProcessRoutes: React.FC = () => {
    const { Process, ProcessCreate } = OrganisationConstants;
    return (
        <Switch>
            <AuthorizeRoute
                path={`/:orgShortName${ProcessCreate}`}
                component={ProcessCreatePage}
            />
            <AuthorizeRoute
                path={`/:orgShortName${Process}/myassigned`}
                component={MyAssignedPage}
            />
            <AuthorizeRoute
                path={`/:orgShortName${Process}/:processId/activity/:activityId`}
                component={ProcessActivityPage}
            />
            <AuthorizeRoute
                path={`/:orgShortName${Process}/:id`}
                component={ProcessPage}
            />
            <AuthorizeRoute
                path={`/:orgShortName${Process}`}
                component={ProcessesListPage}
            />
        </Switch>
    );
};

const OrgAssetRoutes: React.FC = () => {
    const { Asset, AssetCreate } = OrganisationConstants;
    return (
        <Switch>
            <AuthorizeRoute
                path={`/:orgShortName${AssetCreate}`}
                component={AssetCreatePage}
            />
            <AuthorizeRoute
                path={`/:orgShortName${Asset}/:id`}
                component={AssetViewPage}
            />
            <AuthorizeRoute
                path={`/:orgShortName${Asset}`}
                component={AssetListPage}
            />
        </Switch>
    );
};

const OrgFilesRoutes: React.FC = () => {
    const { folder, file, fileSearch } = OrganisationConstants;
    return (
        <Switch>
            <AuthorizeRoute
                path={`/:orgShortName/${fileSearch}`}
                component={FileSearchPage}
            />
            <AuthorizeRoute
                path={`/:orgShortName/${folder}/:folderId/${file}/:fileId`}
                component={FileDetailsPage}
            />
            <AuthorizeRoute
                path={`/:orgShortName/${folder}/:folderId`}
                component={FolderPage}
            />
            <AuthorizeRoute
                path={`/:orgShortName/${folder}`}
                component={FolderRootPage}
            />
        </Switch>
    );
};

const OrgUsersRoutes: React.FC = () => {
    const { users, userGroups, invitations, actionCreate } =
        OrganisationConstants;
    return (
        <Switch>
            <AuthorizeRoute
                path={`/:orgShortName/${userGroups}${actionCreate}`}
                component={UserGroupCreatePage}
            />
            <AuthorizeRoute
                path={`/:orgShortName/${userGroups}/:id`}
                component={UserGroupPage}
            />
            <AuthorizeRoute
                path={`/:orgShortName/${userGroups}`}
                component={UserGroupManagePage}
            />
            <AuthorizeRoute
                path={`/:orgShortName/${invitations}${actionCreate}`}
                component={InvitationPage}
            />
            <AuthorizeRoute
                path={`/:orgShortName/${invitations}`}
                component={InvitationManagement}
            />
            <AuthorizeRoute
                path={`/:orgShortName/${users}`}
                component={UsersManagementPage}
            />
        </Switch>
    );
};

const OrganisationRoutes: React.FC = () => {
    const paths = OrganisationConstants;
    const isCefa = useIsClient(CefaConstants.ClientName);
    const isSsow = useIsClient(SsowConstants.ClientName);
    const isSBIM = useIsClient(SbimConstants.ClientName);

    return (
        <OrganisationLayout>
            <Switch>
                <Route path={`/:orgShortName`} exact>
                    <OrganisationHomeRedirectPage />
                </Route>
                {isCefa && (
                    <AuthorizeRoute
                        path={AppRoutes.MyWork.path}
                        component={MyWorkPage}
                    />
                )}
                {isCefa && (
                    <AuthorizeRoute
                        path={AppRoutes.SchedulerExaminations.path}
                        component={ExaminationSchedulesPage}
                        exact
                    />
                )}
                {isCefa && (
                    <AuthorizeRoute
                        path={AppRoutes.SearchExaminations.path}
                        component={ExaminationSearchPage}
                        exact
                    />
                )}
                {isCefa && (
                    <AuthorizeRoute
                        path={AppRoutes.ExamManagement.path}
                        component={ExamManagementTool}
                        exact
                    />
                )}
                {isCefa && (
                    <AuthorizeRoute
                        path={AppRoutes.MissingCarrsExam.path}
                        component={MissingCarrsExamToolPage}
                        exact
                    />
                )}
                {isCefa && (
                    <AuthorizeRoute
                        path={AppRoutes.ApprovedExams.path}
                        component={DeliveredExamsReportPage}
                        exact
                    />
                )}
                <Route path={`/:orgShortName${paths.Report}`}>
                    <OrgReportRoutes />
                </Route>
                <Route path={`/:orgShortName${paths.Process}`}>
                    <OrgProcessRoutes />
                </Route>
                <Route path={`/:orgShortName${paths.Asset}`}>
                    <OrgAssetRoutes />
                </Route>
                <Route path={AppRoutes.Scheduler.path}>
                    <OrgSchedulerRoutes />
                </Route>
                <Route path={`/:orgShortName/${paths.files}`}>
                    <OrgFilesRoutes />
                </Route>
                <Route path={`/:orgShortName/${paths.users}`}>
                    <OrgUsersRoutes />
                </Route>
                {isCefa && (
                    <Route path={AppRoutes.Workbank.path}>
                        <CefaWorkbankRoutes />
                    </Route>
                )}
                {isCefa && (
                    <Route path={AppRoutes.Batching.path}>
                        <CefaBatchingRoutes />
                    </Route>
                )}
                {isCefa && (
                    <Route path={AppRoutes.EngineerReview.path}>
                        <CefaEngineerReviewRoutes />
                    </Route>
                )}
                {isCefa && (
                    <Route path={AppRoutes.CommercialReport.path}>
                        <CefaReportCommercialExport />
                    </Route>
                )}
                {isCefa && (
                    <Route path={AppRoutes.ProgressReportExport.path}>
                        <CefaReportProgressExport />
                    </Route>
                )}
                {isCefa && (
                    <Route path={AppRoutes.PerformanceReportExport.path}>
                        <CefaReportPerformanceExport />
                    </Route>
                )}
                {isCefa && (
                    <Route path={AppRoutes.ReactiveExamCreator.path}>
                        <CefaReactiveExamCreator />
                    </Route>
                )}
                {isCefa && (
                    <AuthorizeRoute
                        path={AppRoutes.ExamRequests.path}
                        component={ExamRequestsPage}
                    />
                )}
                {isSsow && (
                    <Route path={AppRoutes.SsowDataExport.path}>
                        <SsowDataExport />
                    </Route>
                )}

                {isSBIM && (
                    <Route path={AppRoutes.OrganisationOverview.path}>
                        <OrganisationOverview />
                    </Route>
                )}
                <AuthorizeRoute
                    path={`/:orgShortName/${paths.help}`}
                    component={OrgHelpListPage}
                />
                <AuthorizeRoute
                    path={`/:orgShortName/${paths.defects}/:defectId`}
                    component={DefectDetailsPage}
                />
                <ApcRoutes />
            </Switch>
        </OrganisationLayout>
    );
};

const RootRoutes: React.FC = () => {
    return (
        <Layout>
            <AuthorizeRoute component={OrganisationsPage} />
        </Layout>
    );
};
const ManagementRoutes: React.FC = () => {
    return (
        <Layout>
            <Switch>
                <AuthorizeRoute
                    path="/asset-template/create"
                    component={TemplateCreatePage}
                    additionalComponentProps={{
                        TemplateType: TemplateTypes.Asset,
                    }}
                />
                <AuthorizeRoute
                    path="/form/template/create"
                    component={TemplateCreatePage}
                    additionalComponentProps={{
                        TemplateType: TemplateTypes.Form,
                    }}
                />
                <AuthorizeRoute
                    path="/asset-template/:id"
                    component={TemplateViewPage}
                    additionalComponentProps={{
                        TemplateType: TemplateTypes.Asset,
                    }}
                />
                <AuthorizeRoute
                    path="/form/template/:id"
                    component={TemplateViewPage}
                    additionalComponentProps={{
                        TemplateType: TemplateTypes.Form,
                    }}
                />
                <AuthorizeRoute
                    path="/organisation/create"
                    component={OrganisationCreatePage}
                />
                <AuthorizeRoute
                    path="/organisation/:orgId/help-items/create"
                    component={HelpSectionPage}
                />
                <AuthorizeRoute
                    path="/organisation/:orgId/help-items/:itemId"
                    component={HelpSectionPage}
                />
                <AuthorizeRoute
                    path="/organisation/:id"
                    component={OrganisationManagePage}
                />
                <AuthorizeRoute
                    path="/process/template/create"
                    component={ProcessTemplateCreatePage}
                />
                <AuthorizeRoute
                    path="/process/template/:id"
                    component={ProcessTemplateViewPage}
                />
                <AuthorizeRoute
                    path="/process-template/:processTemplateId/report-template/create"
                    component={ReportTemplateCreatePage}
                />
                <AuthorizeRoute
                    path="/process-template/:processTemplateId/report-template/:processReportTemplateId"
                    component={ReportTemplateViewPage}
                />
            </Switch>
        </Layout>
    );
};

const AdminRoutes: React.FC = () => {
    return (
        <Layout>
            <Switch>
                <AuthorizeRoute
                    path={`${AdminConstants.RolesPagePath}/:id`}
                    component={RoleAdminPage}
                    requiredRoles={[Role.Admin]}
                />
                <AuthorizeRoute
                    path={AdminConstants.RolesPagePath}
                    component={RolesAdminPage}
                    requiredRoles={[Role.Admin]}
                />
                <AuthorizeRoute
                    path={AdminConstants.UsersPagePath}
                    component={UsersAdminPage}
                    requiredRoles={[Role.Admin]}
                />
                <AuthorizeRoute
                    component={AdminHomePage}
                    requiredRoles={[Role.Admin]}
                />
            </Switch>
        </Layout>
    );
};

// * This is where all the URL routes need to be listed with possible parameters
const App: React.FC = () => (
    <IntlProvider locale="en-GB">
        <NotificationsConnection />
        <Switch>
            <Route
                path={"/accept-invitation"}
                component={InvitationRedirectPage}
            />
            <Route
                path={"/profile-updated"}
                component={UpdateUserRedirectPage}
            />

            <AuthorizeRoute
                path={[
                    "/form",
                    "/organisation",
                    "/process",
                    "/authentication",
                    "/userGroup",
                    "/process-template",
                    "/asset-template",
                    "/progress-report-export",
                    "/performance-report-export",
                ]}
            >
                <ManagementRoutes />
            </AuthorizeRoute>

            <AuthorizeRoute path={[AdminConstants.ResourceName]}>
                <AdminRoutes />
            </AuthorizeRoute>

            <AuthorizeRoute exact path={["/", "", "/home"]}>
                <RootRoutes />
            </AuthorizeRoute>

            <AuthorizeRoute path="/:orgShortName">
                <OrganisationRoutes />
            </AuthorizeRoute>
        </Switch>
        <NotificationToastsWrapper />
    </IntlProvider>
);

export default App;
