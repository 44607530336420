import { GridRowClickEvent } from "@progress/kendo-react-grid";
import { push } from "connected-react-router";
import * as React from "react";
import { Badge, Breadcrumb } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import QueryResultStatus from "../../../common/components/QueryResultStatus";
import { useOrgTitleWithShortName } from "../../../common/hooks/useTitle";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { useSearchFilesAndFoldersQuery } from "../../../store/features/file/fileApiSlice";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";
import { FileAndFoldersGrid } from "../components/FileAndFoldersGrid";
import FileSearch from "../components/FileSearch";
import FilePageTitles from "../pageTitles";
import {
    buildFormFromQuery,
    checkValidSearch,
    mapSeachToViewEntry,
} from "../viewModel/FileSearch";
import {
    EntryType,
    FileViewEntry,
    GroupedFileViewEntries,
} from "../viewModel/FileViewEntry";
import { useGetOrgByShortNameQuery } from "../../../store/features/organisation/organisation-api-slice";

const searchColumnConfig = {
    dateModified: true,
    location: true,
};

const FileSearchPage: React.FC = (): JSX.Element => {
    const { orgShortName } = useParams<{ orgShortName: string }>();
    const location = useLocation();
    const dispatch = useDispatch();
    const { data: organisation } = useGetOrgByShortNameQuery(orgShortName, {
        skip: !orgShortName,
    });
    const { organisationId, isLoadingOrgId } = useOrganisationId();
    const currentQuery = React.useMemo(
        () => buildFormFromQuery(location.search),
        [location.search],
    );

    useOrgTitleWithShortName(`${FilePageTitles.Search}: ${currentQuery.name}`);
    const navigateToResult = React.useCallback(
        (event: GridRowClickEvent): void => {
            const consts = OrganisationConstants;
            const { id, parentId, type } = event.dataItem as FileViewEntry;

            switch (type) {
                case EntryType.Folder:
                    dispatch(push(`/${orgShortName}/${consts.folder}/${id}`));
                    break;
                case EntryType.File:
                    dispatch(
                        push(
                            `/${orgShortName}/${consts.folder}/${parentId}/${consts.file}/${id}`,
                        ),
                    );
            }
        },
        [dispatch, orgShortName],
    );

    const isValidSearch = checkValidSearch(currentQuery);

    const { data, ...searchQueryResult } = useSearchFilesAndFoldersQuery(
        { organisationId: organisationId, query: currentQuery },
        { skip: isLoadingOrgId || !isValidSearch },
    );

    const entries = React.useMemo(
        (): GroupedFileViewEntries => ({
            fileEntries: data
                ? data.map((pFile) => mapSeachToViewEntry(pFile))
                : [],
            virtualEntries: [],
            folderEntries: [],
        }),
        [data],
    );

    return (
        <>
            <div className="pt-4">
                <Breadcrumb>
                    <Breadcrumb.Item linkAs="span">
                        <Link
                            to={`/${orgShortName}/${OrganisationConstants.folder}`}
                        >
                            {organisation?.features?.files?.sideMenuName ??
                                "files"}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Search</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="pt-4">
                <FileSearch
                    initialQuery={currentQuery}
                    organisation={organisation}
                />
            </div>
            <div className="pt-4">
                {isValidSearch ? (
                    <h5>
                        Showing results for{" "}
                        <Badge variant="primary">{currentQuery.name}</Badge>
                    </h5>
                ) : (
                    <h5>
                        Select search query to look for the{" "}
                        {organisation?.features?.files?.sideMenuName ?? "files"}
                    </h5>
                )}
            </div>
            {isValidSearch && (
                <div className="pt-4">
                    <QueryResultStatus queryResult={searchQueryResult} />
                    {data && (
                        <FileAndFoldersGrid
                            key={location.search}
                            entries={entries}
                            onRowClick={navigateToResult}
                            additionalColumns={searchColumnConfig}
                            gridEmptyText="There are no results that match your search."
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default FileSearchPage;
