import * as React from "react";
import { Process } from "../../process/domain/types";
import { ProcessActivity } from "../domain/types";
import ProcessActivityBreadcrumb from "./ProcessActivityBreadcrumb";
import ProcessActivityNavigationBar from "./ProcessActivityNavigationBar";
import { Organisation } from "../../organisation/domain/types";
interface Props {
    process: Process;
    activity: ProcessActivity;
    organisation: Organisation;
}

const ProcessActivityMainTopBar: React.FC<Props> = ({
    process,
    activity,
    organisation,
}) => {
    return (
        <>
            <ProcessActivityBreadcrumb
                process={process}
                activity={activity}
                organisation={organisation}
            ></ProcessActivityBreadcrumb>
            <h1>{activity?.displayName ?? ""}</h1>
            <hr />
            <ProcessActivityNavigationBar
                process={process}
                activity={activity}
                orgShortName={organisation.shortName}
            ></ProcessActivityNavigationBar>
            <hr />
        </>
    );
};

export default ProcessActivityMainTopBar;
