import * as React from "react";
import { Tab, Tabs } from "react-bootstrap";
import AuditActionsList from "../../../common/components/audit/AuditActionsList";
import { useOrgTitleWithShortName } from "../../../common/hooks/useTitle";
import { useGetProcessByIdQuery } from "../../../store/features/process/process-api-slice";
import { useAppSelector } from "../../../store/hooks";
import AssetLinkTab from "../../process-activity/components/AssetLinkTab";
import ProcessActivityWrapper from "../../process-activity/components/ProcessActivityWrapper";
import { formatActivityPageTitle } from "../../process-activity/pages/pageTitles";
import {
    useActivityNotApplicableMutation,
    useActivityOpenMutation,
    useGetActivityByIdQuery,
} from "../store/processActivityApprovalApiSlice";
import ProcessActivityApprovalContent from "./ProcessActivityApprovalContent";
import { Organisation } from "../../organisation/domain/types";

interface Props {
    processId: string;
    activityId: string;
    organisation: Organisation;
}

const ProcessActivityApprovalWrapper: React.FC<Props> = ({
    processId,
    activityId,
    organisation,
}) => {
    const userName = useAppSelector((state) => state.authUser?.username);

    const { data: process, ...processResult } = useGetProcessByIdQuery(
        processId,
        { skip: !processId },
    );

    const { data: activityApproval, ...activityApprovalResult } =
        useGetActivityByIdQuery(
            { activityId, processId },
            {
                skip: !activityId,
            },
        );

    useOrgTitleWithShortName(
        formatActivityPageTitle(
            process?.name,
            activityApproval?.displayName,
            !process || !activityApproval,
        ),
    );

    const [setNotApplicableApproval, notApplicableApprovalResult] =
        useActivityNotApplicableMutation();
    const [openApproval, openApprovalResult] = useActivityOpenMutation();

    const activeUserApproval = React.useMemo(
        () =>
            activityApproval?.approvals.find(
                (a) =>
                    a.approver.userEmail.toLowerCase() ===
                    userName.toLowerCase(),
            ),
        [activityApproval, userName],
    );

    const assetLinkEnabled = activityApproval?.assetLink?.enabled;

    return (
        <ProcessActivityWrapper
            processQuery={[process, processResult]}
            activityQuery={[activityApproval, activityApprovalResult]}
            activityActions={{
                notApplicable: {
                    actionCallback: setNotApplicableApproval,
                    actionResult: notApplicableApprovalResult,
                },
                open: {
                    actionCallback: openApproval,
                    actionResult: openApprovalResult,
                },
            }}
            organisation={organisation}
        >
            {process && activityApproval && (
                <Tabs defaultActiveKey="content" id="tab">
                    <Tab eventKey="content" title="Content">
                        <ProcessActivityApprovalContent
                            organisationId={process.organisationId}
                            activity={activityApproval}
                            approvalForCurrentUser={activeUserApproval}
                            approversDisplayName={
                                activityApproval.approversDisplayName
                            }
                            approvalTitle={activityApproval.approvalDisplayName}
                        />
                    </Tab>
                    {assetLinkEnabled && (
                        <Tab eventKey="asset" title="Assets">
                            <AssetLinkTab
                                activity={activityApproval}
                                organisation={organisation}
                            />
                        </Tab>
                    )}
                    <Tab eventKey="history" title="History">
                        <AuditActionsList
                            auditActions={activityApproval.auditActions ?? []}
                        />
                    </Tab>
                </Tabs>
            )}
        </ProcessActivityWrapper>
    );
};

export default ProcessActivityApprovalWrapper;
